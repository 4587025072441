import { apiFetcher, apiFetcherFormData } from "../services/API.service";
import { ManageAttributes } from "../types";

//===============================================================================\
//===========================Validation Functions ===============================
function isValidAttributes(data: unknown): data is ManageAttributes[] {
  if (!Array.isArray(data)) return false;
  return data.every((item) => {
    if (typeof item.id !== "number") {
      console.log("Failed to validate id", item.id);
      return false;
    }
    if (typeof item.name !== "string") {
      console.log("Failed to validate name", item.name);
      return false;
    }
    if (typeof item.code !== "string") {
      console.log("Failed to validate code", item.code);
      return false;
    }
    if (typeof item.active !== "boolean") {
      console.log("Failed to validate active", item.active);
      return false;
    }
    if (typeof item.type !== "number") {
      console.log("Failed to validate type", item.type);
      return false;
    }
    //IF all is true
    return true;
  });
}

//===============================================================================
//===========================Example Data =======================================

export const fetchAttributes = async (
  clientID: string
): Promise<ManageAttributes[] | string> => {
  try {
    const response = await apiFetcher<unknown>(
      "/client/attribute/get/all",
      "POST",
      {
        body: { clientID: clientID, allAttributes: true },
      }
    );
    if (response.status === 200 && response.data) {
      if (isValidAttributes(response.data)) {
        return response.data;
      } else {
        console.log("Failed to validate data", response.data);
        return "Failed to validate data";
      }
    } else if (response.status === 204) {
      console.log("No content found");
      return [];
    } else if (response.status === 401) {
      return "Unauthorized";
    } else {
      return "Failed to get a response";
    }
  } catch (error) {
    console.error("An error occurred while fetching attributes:", error);
    return "An error occurred while fetching attributes";
  }
};

export const updateAttributes = async (
  clientID: string,
  attributes: ManageAttributes[]
): Promise<string> => {
  try {
    const response = await apiFetcher<unknown>(
      "/client/attribute/update",
      "POST",
      {
        body: { clientID, attributes },
      }
    );
    if (response.status === 200) {
      return "success";
    } else if (response.status === 204) {
      return "No content found";
    } else if (response.status === 401) {
      return "Unauthorized";
    } else {
      return "Failed to get a response";
    }
  } catch (error) {
    console.error("An error occurred while updating attributes:", error);
    return "An error occurred while updating attributes";
  }
};

export const assignAttributes = async (
  clientID: string,
  imageIDs: string[],
  attributeID: string,
  value: string
): Promise<string> => {
  try {
    const response = await apiFetcher<unknown>(
      "/client/attribute/attributevalue/add",
      "POST",
      {
        body: { clientID, imageIDs, attributeID, value },
      }
    );
    if (response.status === 200) {
      return "success";
    } else if (response.status === 204) {
      return "No content found";
    } else if (response.status === 401) {
      return "Unauthorized";
    } else {
      return "Failed to get a response";
    }
  } catch (error) {
    console.error("An error occurred while assigning attributes:", error);
    return "An error occurred while assigning attributes";
  }
};

export async function sendReimportRequest(
  clientID: string
): Promise<
  { rStatus: "success"; rData: "normal" | "wsc" } | { rStatus: "error" }
> {
  try {
    const response = await apiFetcher("/wsc/import", "POST", {
      body: { clientID: clientID },
    });

    if (response.status === 201) {
      return { rStatus: "success", rData: "normal" };
    } else if (response.status === 202) {
      return { rStatus: "success", rData: "wsc" };
    } else {
      return { rStatus: "error" };
    }
  } catch (error) {
    console.error("Failed to send reimport request:", error);
    return { rStatus: "error" };
  }
}

export async function importAttributesCSV(formData: FormData): Promise<string> {
  try {
    const response = await apiFetcherFormData(
      "/image/upload/attributeCSV",
      "POST",
      {
        body: formData,
      }
    );
    if (response.status === 200) {
      return "success";
    } else if (response.status === 202) {
      return "success waiting";
    } else {
      console.error("Failed to import attributes CSV:", response);
      return "failed";
    }
  } catch (error) {
    console.error("Failed to import attributes CSV:", error);
    return "failed";
  }
}
