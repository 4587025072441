import ExcelJS from "exceljs";
import localforage from "localforage";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { ReactComponent as EditIcon } from "../../../assets/images/icons/edit.svg";
import { ReactComponent as ImportIcon } from "../../../assets/images/icons/ImportIcon.svg";
import { ReactComponent as UploadIcon } from "../../../assets/images/icons/upload.svg";
import { useAttributeContext } from "../../../context/UserAttributeContext";
import {
  importAttributesCSV,
  sendReimportRequest,
} from "../../../models/wsc.model";
import {
  bodyData,
  bodySingleData,
  ClientResponse,
  ClientType,
  HeaderType,
} from "../../../types";
import "../uploadImage.scss";

interface WSCImportsProps {
  setWindowToDisplay: React.Dispatch<React.SetStateAction<string>>;
  openWscAttributeModal: () => void;
}

const WSCImports: React.FC<WSCImportsProps> = ({
  setWindowToDisplay,
  openWscAttributeModal,
}) => {
  const { clientID } = useParams<{ clientID: string }>();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [selectedClient, setSelectedClient] = useState<
    ClientType | null | "loading"
  >("loading");

  useEffect(() => {
    if (!clientID) {
      setSelectedClient(null);
      return;
    }

    const fetchClient = async () => {
      const allClients: string | null = await localforage.getItem("allClients");

      if (typeof allClients === "string") {
        try {
          const arrayOfClients: ClientResponse = JSON.parse(allClients);

          if (arrayOfClients.clients) {
            const client = arrayOfClients.clients.find(
              (client: ClientType) => client.client_id.toString() === clientID
            );
            if (client) {
              setSelectedClient(client);
            } else {
              setSelectedClient(null);
            }
          }
        } catch (error) {
          console.log("Error parsing client data", error);
          setSelectedClient(null);
        }
      }
    };

    fetchClient();
  }, [clientID]);

  const userAttributeContext = useAttributeContext();

  const openReimportModal = async () => {
    if (!selectedClient || selectedClient === "loading") {
      Swal.fire({
        title: "Client not found",
        text: "Please select a client from the dashboard",
        icon: "error",
      });
      return;
    }
    Swal.fire({
      title: selectedClient.wsc
        ? "Re-Import Images from WSC"
        : "Re-Import Auto board Images",
      html: `Confirm your action. Please be aware that proceeding with this request will require substantial processing resources and may incur significant costs. To proceed, please enter the client name <strong>${selectedClient.name}</strong> to confirm.`,
      input: "text",
      inputPlaceholder: "Client Name",
      showCancelButton: true,
      confirmButtonText: "Reimport",
      cancelButtonText: "Cancel",
      inputValidator: (value) => {
        if (!value) {
          return "Please enter the client name";
        }
        if (value !== selectedClient.name) {
          return "Client name does not match, Please try again";
        }
      },
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Re-Import requested",
          html: "Please wait...",
          allowOutsideClick: false,
          allowEscapeKey: false,
          showConfirmButton: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });

        const reimportRequest = async () => {
          const reimportRequest = await sendReimportRequest(
            selectedClient.client_id
          );
          Swal.close();

          if (reimportRequest.rStatus === "success") {
            if (reimportRequest.rData === "normal") {
              Swal.fire({
                title: "Re-import request sent",
                text: "Thank you. The re-import process is now running in the background. We appreciate your patience.",
                icon: "success",
              });
            } else {
              Swal.fire({
                title: "Re-import WSC request sent",
                text: "Thank you. The re-import process is now running in the background. We appreciate your patience.",
                icon: "success",
              });
            }
          } else {
            Swal.fire({
              title: "Re-Import request failed",
              text: "Please try again",
              icon: "error",
            });
          }
        };
        reimportRequest();
      }
    });
  };

  // CSV UPLOAD

  // Display Initial CSV requirements to Client before file upload
  const displayCsvInfo = () => {
    Swal.fire({
      title: "CSV Import Information",
      icon: "info",
      html: `
      Please ensure that the CSV file you are importing is formatted correctly:<br><br>
      <div style="text-align: left; height: 250px; overflow-y: auto; border: solid 1px; border-radius: 5px; padding: 5px; font-size: 0.9rem">
      <strong>Formatting:</strong>
      <hr>
      1) Must contain Image ID<br><br>
      2) If a value is empty and there is an existing value for the image for that attribute, it will be removed<br><br>
      3) All spaces will be converted to underscores<br><br>
      4) If there are duplicate attributes, the second one will be ignored<br><br>
      5) Download the bulk Image data export to see the required format structure<br><br>
      6) Each row must maintain the original image ID<br><br>
      7) You can modify other fields as needed - WSC fields will remain unchanged<br><br>
      <strong>Import Process:</strong><br>
      <hr>
      1) Update your data in the exported CSV file<br><br>
      2) Ensure the image ID column remains unchanged<br><br>
      3) Save the file in CSV format<br><br>
      4) Click OK to import the modified file<br><br>
      </div>
    `,
      allowOutsideClick: false,
      allowEscapeKey: false,
      showConfirmButton: true,
      confirmButtonColor: "#3085d6",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      showCloseButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        handleLabelClick();
      }
    });
  };

  const handleLabelClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (!file) {
      resetFileInput();
      return;
    }

    // Cannot be greater than 100mb
    if (file.size > 100000000) {
      Swal.fire({
        title: "File too large",
        text: "Please upload a file that is less than 100MB",
        icon: "error",
      });
      resetFileInput();
      return;
    }

    // get file extension
    const extension = file.name.split(".").pop();

    // Only allow CSV files
    if (extension !== "csv") {
      Swal.fire({
        title: "Unsupported file format",
        text: "Please upload a CSV file",
        icon: "error",
      });
      resetFileInput();
      return;
    }

    if (file && clientID) {
      Swal.fire({
        title: "Uploading...",
        html: "Please wait while the CSV is being uploaded. <br><br> This may take a few minutes.",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      const reader = new FileReader();

      // Sending CSV file to server
      reader.onload = async (e) => {
        console.time("CSV Upload");
        const formData = new FormData();
        formData.append("file", file);
        formData.append("clientID", clientID);

        // Request to upload CSV (FormData)
        const csvUploadResponse: string | number = await importAttributesCSV(
          formData
        );

        // TODO: look into responses.
        if (typeof csvUploadResponse === "string") {
          console.timeEnd("CSV Upload");

          Swal.close();
          resetFileInput();
          if (csvUploadResponse === "success") {
            Swal.fire({
              title: "CSV Import Successful",
              text: "Attributes have been imported successfully",
              icon: "success",
              showConfirmButton: true,
              confirmButtonColor: "#3085d6",
            }).then((result) => {
              userAttributeContext.updateAttributeData(clientID);
              result.isConfirmed && setWindowToDisplay("upload");
            });
          } else if (csvUploadResponse === "success waiting") {
            Swal.fire({
              title: "CSV Upload Successful",
              html: "Attributes are being processed!<br><br>You will receive an email when the process is complete",
              icon: "success",
              showConfirmButton: true,
              confirmButtonColor: "#3085d6",
            }).then((result) => {
              result.isConfirmed && setWindowToDisplay("upload");
            });
          } else {
            Swal.fire({
              title: "CSV Import Error",
              text: "Image Attributes could not be imported",
              icon: "error",
              showConfirmButton: true,
              confirmButtonColor: "#3085d6",
            }).then((result) => {
              result.isConfirmed && setWindowToDisplay("upload");
            });
          }
        } else {
          if (csvUploadResponse === 406) {
            Swal.fire({
              title: "CSV Import Error",
              text: "Image ID is not present in first column for all images",
              icon: "error",
              showConfirmButton: true,
              confirmButtonColor: "#3085d6",
            }).then((result) => {
              result.isConfirmed && setWindowToDisplay("upload");
            });
          } else if (csvUploadResponse === 400) {
            Swal.fire({
              title: "CSV Import Error",
              text: "CSV Data could not be parsed",
              icon: "error",
              showConfirmButton: true,
              confirmButtonColor: "#3085d6",
            }).then((result) => {
              result.isConfirmed && setWindowToDisplay("upload");
            });
          } else {
            Swal.fire({
              title: "CSV Import Error",
              text: "Image Attributes could not be imported",
              icon: "error",
              showConfirmButton: true,
              confirmButtonColor: "#3085d6",
            }).then((result) => {
              result.isConfirmed && setWindowToDisplay("upload");
            });
          }
          resetFileInput();
        }
      };

      reader.onerror = (error) => {
        console.error("FileReader error:", error);
        Swal.fire({
          title: "File Read Error",
          text: "An error occurred while reading the file",
          icon: "error",
        });
        resetFileInput();
      };

      // Start reading the file
      reader.readAsArrayBuffer(file);
    }
  };

  const resetFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  return selectedClient === "loading" ? (
    <h3>Loading...</h3>
  ) : !selectedClient ? (
    <h3>No Client with ID {clientID} found</h3>
  ) : (
    <div className="import-wsc">
      <div
        id="upload_image_page_component_container"
        className="uk-flex uk-grid no_image form_container"
      >
        <div className="upload_form_container">
          <form id="upload_form">
            <fieldset className="uk-fieldset">
              <div className="upload_form_title">Manage Attributes</div>
              <EditIcon className="upload_icon" />
              <div id="upload_form_submit_container">
                <label
                  className="upload_form_submit_label"
                  onClick={(e) => setWindowToDisplay("wscEdit")}
                >
                  Manage
                </label>
              </div>
            </fieldset>
          </form>
        </div>
        <div className="upload_form_container">
          <form id="upload_form">
            <fieldset className="uk-fieldset">
              <div className="upload_form_title">
                {selectedClient.wsc
                  ? "Re-import Images from WSC"
                  : "Re-import Auto board Images"}
              </div>
              <UploadIcon className="upload_icon" />
              <div id="upload_form_submit_container">
                <label
                  className="upload_form_submit_label"
                  onClick={(e) => openReimportModal()}
                >
                  Re-import
                </label>
              </div>
            </fieldset>
          </form>
        </div>
        <div className="upload_form_container">
          <form id="upload_form">
            <fieldset className="uk-fieldset">
              <div className="upload_form_title">Import Attributes CSV</div>
              <ImportIcon className="upload_icon" />
              <div id="upload_form_submit_container">
                <label
                  className="upload_form_submit_label"
                  onClick={displayCsvInfo}
                >
                  Import
                </label>
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  accept=".csv"
                  onChange={handleFileChange}
                  multiple={false}
                />
              </div>
            </fieldset>
          </form>
        </div>
      </div>
    </div>
  );
};

export default WSCImports;
